<template>
<div class="bodyhome">

  <div class="d-flex flex-row  mb-12">
    <v-card
    class=""
    min-height="760"
    width="256"
  >
    <v-navigation-drawer
      dark
      permanent
    >
      <v-list>
        <div class="pa-2">
          <v-btn v-on:click="menu" block>
            Menu
          </v-btn>
        </div>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          v-on:click="navigate(item.route)"
         >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title> 
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="logout" block>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    </v-card>
  
  <horizontal-scroll>

    <div class="mx-auto" style="padding: 50px; text-align: center;">

    <v-row>
    <v-col
      cols="3"
      sm="3"
      md="3"
    >
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y

      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="startDate"
            label="Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          no-title
          scrollable
          @input="$refs.menu1.save(startDate)"
        >
          <!-- <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
          @click="$refs.menu1.save(startDate)"
            text
            color="primary"
          >
            OK
          </v-btn> -->
        </v-date-picker>
      </v-menu>
    </v-col>
 
    <v-col
      cols="3"
      sm="3"
      md="3"
    >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="endDate"
            label="End Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          no-title
          scrollable
          @click="$refs.menu2.save(endDate)"
        >      
          <!-- <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            @click="$refs.menu2.save(endDate)"
            text
            color="primary"
       
          >
            OK
          </v-btn> -->
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      sm="2"
      md="2"
    >
   <v-btn
   @click="filterData"
   color="primary"
   >
    Filter
   </v-btn>
  </v-col>

  <v-col
      cols="3"
      sm="3"
      md="3"
    >
    <v-text-field
        class="rounded ms-auto"
        append-icon="mdi-magnify"
        v-model="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
   
  </v-col>

    </v-row>

<v-data-table
  :headers="headers"
  :items="desserts"
  :search="search"
  sort-by="calories"
  class="elevation-1 "
>
  <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-toolbar-title>Transactions</v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialogEdit"
        max-width="500px"
      >
    
        <v-card>
          <v-card-title>
            <span class="text-h5">Edit Transaction</span>
          </v-card-title>
          <v-card-text>
            <v-container>

              <form class="d-flex " >

<div >
  <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"

      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="editeditem.tgltransaksi"
            label="Tgl Transaksi"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="editeditem.tgltransaksi"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

<v-text-field
v-model="editeditem.namapemesan"
label="Nama Pemesan"
></v-text-field>

<v-text-field
v-model="editeditem.nohp"
label="No HP"   
type="number"
></v-text-field>

<v-text-field
v-model="editeditem.namapengguna"
label="Nama Pengguna"
></v-text-field>

<v-text-field
v-model="editeditem.noktp"
label="No Ktp"
type="number"
></v-text-field>

<v-text-field
        v-model="editeditem.refund"
        label="Refund IN"
        type="number"
        ></v-text-field>

</div>

<div style="margin-left:50px;">

<v-text-field
v-model="editeditem.email"
label="Email"
type="email"
></v-text-field>

<v-text-field
v-model="editeditem.modatransportasi"
label="Jenis Tiket (Maskapai)"
></v-text-field>

 <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        max-width="290px"

      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="editeditem.tglberangkat"
            label="Tgl Keberangkatan"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="editeditem.tglberangkat"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(date)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

        <v-text-field
        v-model="editeditem.tempatberangkat"
        label="Keberangkatan"
        ></v-text-field>

        <v-text-field
        v-model="editeditem.tujuan"
        label="Tujuan"
        ></v-text-field>

        <v-text-field
        v-model="editeditem.refundout"
        label="Refund OUT"
        type="number"
        ></v-text-field>

        </div>

        <div style="margin-left:50px;">

        <v-text-field
        v-model="editeditem.kodebooking"
        label="Kode Booking"
        ></v-text-field>

        <v-text-field
        v-model="editeditem.totaltagihan"
        label="Harga Tiket"
        type="number"
        ></v-text-field>

    

        <v-text-field
        v-model="editeditem.kategori"
        label="Kategori"
        ></v-text-field>

        <v-text-field
        v-model="editeditem.komisicitos"
        label="Komisi Citos"
        type="number"
        ></v-text-field>


        <v-text-field
        v-model="editeditem.komisijamkrindo"
        label="Komisi Jamkrindo"
        type="number"
        :disabled=true
        ></v-text-field>


        <v-text-field
        v-model="editeditem.addon"
        label="AddOn"
        type="number"
        ></v-text-field>


        </div>



        </form>
           
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialogEdit = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="updateData"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogDelete = false" color="blue darken-1" text >Cancel</v-btn>
            <v-btn @click="postDelete" color="blue darken-1" text >OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogRefund" max-width="500px">
        <v-card style="padding: 30px;">
          <v-text-field
             v-model="refundnominal"
             label="Refund Masuk"
             type="number"
            ></v-text-field>

            <v-menu
        ref="menurefund"
        v-model="menurefund"
        :close-on-content-click="false"
        :return-value.sync="tglrefund"
        transition="scale-transition"
        offset-y
        max-width="290px"
      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="tglrefund"
            label="Tgl Refund Masuk"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tglrefund"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menurefund = false"
          >
            Cancel
          </v-btn>
          <v-btn
          @click="$refs.menurefund.save(tglrefund)"
            text
            color="primary"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

            <v-text-field
             v-model="refundout"
             label="Refund Keluar"
             type="number"
            ></v-text-field>

            <v-menu
        ref="menurefundout"
        v-model="menurefundout"
        :close-on-content-click="false"
        :return-value.sync="tglrefundout"
        transition="scale-transition"
        offset-y
        max-width="290px"
      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="tglrefundout"
            label="Tgl Refund Keluar"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tglrefundout"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menurefundout = false"
          >
            Cancel
          </v-btn>
          <v-btn
          @click="$refs.menurefundout.save(tglrefundout)"
            text
            color="primary"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>

       
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogRefund = false" color="blue darken-1" text >Cancel</v-btn>
            <v-btn @click="updateData" color="blue darken-1" text >OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogAddon" max-width="500px">
        <v-card style="padding: 30px;">
          <v-text-field
             v-model="addonnominal"
             label="Nominal addon"
             type="number"
            ></v-text-field>

            <v-menu
        ref="menuaddon"
        v-model="menuaddon"
        :close-on-content-click="false"
        :return-value.sync="tgladdon"
        transition="scale-transition"
        offset-y
        max-width="290px"

      >
        <template v-slot:activator="{  on, attrs }">
          <v-text-field
            v-model="tgladdon"
            label="Tgl Addoon"
            prepend-icon="mdi-calendar"
            readonly
            v-on="on"
            v-bind="attrs"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="tgladdon"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menuaddon = false"
          >
            Cancel
          </v-btn>
          <v-btn
          @click="$refs.menuaddon.save(tgladdon)"
            text
            color="primary"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="dialogAddon = false" color="blue darken-1" text >Cancel</v-btn>
            <v-btn @click="updateData" color="blue darken-1" text >OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
  </template>

  <template v-slot:[`item.totaltagihan`]="{ item }">

    <p style="font-size: 13px;">{{ formatRupiah(item.totaltagihan) }}</p>

  </template>

  <template v-slot:[`item.komisicitos`]="{ item }">

<p style="font-size: 13px;">{{ formatRupiah(item.komisicitos) }}</p>

</template>

  
  <template v-slot:[`item.refund`]="{ item }">

<p style="font-size: 13px;">{{ formatRupiah(item.refund) }}</p>

</template>

  
<template v-slot:[`item.addon`]="{ item }">

<p style="font-size: 13px;">{{ formatRupiah(item.addon) }}</p>

</template>

  <template v-slot:[`item.actions`]="{ item }">

    <v-icon
      small
      class="mr-2"
      @click="editItem(item)"
    >
      mdi-pencil
    </v-icon>
    <v-btn @click="refund(item)" v-if="isAdmin" style="width: 50px; height: 30px; font-size: 9px;"> 
      refund
    </v-btn>

    <v-btn @click="addOn(item)" v-if="isAdmin" style="width: 50px; height: 30px; margin-left: 8px; font-size: 9px;"> 
      Add on
    </v-btn>
    <v-icon
      v-if="isSupervisor"
      small
      @click="deleteItem(item)"
    >
      mdi-delete
    </v-icon>
  </template>
  <template v-slot:no-data>
    <p>No Data</p>
  </template>
</v-data-table>

</div>
  </horizontal-scroll>

  </div>

</div>
</template>

<script>
 import HorizontalScroll from 'vue-horizontal-scroll'
 import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
 import Swal from 'sweetalert2'
 import axios from 'axios';
 import constant from '../util/constant';

export default {
  name: 'transaction-page',
  data () {
    return {
      date: "",
      search:"",
      isSupervisor:false,
      isAdmin:false,
      menu1: false,
      modal: false,
      menu2: false,
      menurefund:false,
      menurefundout:false,
      menuaddon:false,
      tgltransaksi:"",
      tglrefund:"",
      tgladdon:"",
      startDate: "",
      endDate:"",
        items: [
          { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
          { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
          { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
          { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
          { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
        ],
        itemAdmin:[
      { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
        { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      itemSpv: [
        { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      dialog: false,
      dialogEdit:false,
      dialogDelete: false,
      dialogRefund: false,
      headers: [
        {
          text: 'Tgl Transaksi',
          align: 'start',
          sortable: false,
          value: 'tgltransaksi',
        },
        { text: 'Nama pemesan', value: 'namapemesan' },
        { text: 'No Hp', value: 'nohp' },
        { text: 'Nama Pengguna', value: 'namapengguna' },
        { text: 'No Ktp', value: 'noktp' },
        { text: 'Email', value: 'email' },
        { text: 'Katergori', value: 'kategori' },
        { text: 'Jenis TIket (Maskapai)', value: 'modatransportasi' },
        { text: 'Tanggal Keberangkatan', value: 'tglberangkat' },
        { text: 'Keberangkatan', value: 'tempatberangkat' },
        { text: 'Tujuan', value: 'tujuan' },
        { text: 'Kode Booking', value: 'kodebooking' },
        { text: 'Harga Tiket', value: 'totaltagihan' },
        { text: 'Status', value: 'status' },
        { text: 'Status Refund', value: 'refund' },
        { text: 'AddOn', value: 'addon' },
        { text: 'Komisi Citos', value: 'komisicitos' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      desserts: [],
      editedIndex: -1,
      deleteId:0,
      editeditem: {},
      dialogAddon:false,
      refundnominal :0,
      refundout:0,
      addonnominal:0,
      tglrefundout:""
      }
    },

    components: {
        HorizontalScroll
    },
    
    methods: {
      menu(){
        this.$router.push('/home');
      },
       formatRupiah(number) {
          const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
          });

          return formatter.format(number);
        },
  
      navigate(link) {
      this.$router.push(link);
      },
      getData(){

        let a = this

        axios.get(constant.BASE_URL + "ticket")
      .then(function (response) {

       let newdata = response.data.data;

       console.log(newdata)

       a.desserts = newdata
      
      }).catch(function (error) {     
        console.log(error);
      })
      },

      editItem (item) {
        console.log(item)
        this.editeditem = item
        this.dialogEdit = true
      },

      deleteItem (item) {
        console.log(item)
        this.deleteId = item.id
        this.dialogDelete = true
      },
      postDelete(){

        let a = this

        axios.delete(constant.BASE_URL + "ticket/" + this.deleteId )
        .then(function (response) {

        let newdata = response.data.data;
     

        console.log(newdata)
        a.dialogDelete = false 

        Swal.fire({
            icon: 'success',
            title: 'Data berhasil dihapus',
            showConfirmButton: false
          })

        a.getData()

        }).catch(function (error) {     
        a.dialogDelete = false 
        console.log(error);

        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  error
          })
        })

      },
      refund(item){
        this.dialogRefund = true
        this.editeditem = item
        this.refundnominal =this.editeditem.refund
        this.refundout = this.editeditem.refundout
        this.tglrefund =  this.editeditem.tglrefund 
        this.tglrefundout = this.editeditem.tglrefundout
         
      },
      addOn(item){
        this.dialogAddon = true
        this.editeditem = item
        this.addonnominal = this.editeditem.addon
        this.tgladdon =  this.editeditem.tgladdon 
       
      },
      filterData(){
        const filteredData = this.desserts.filter(item => {
          const itemDate = new Date(item.tgltransaksi);
          return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
        });

        this.desserts = filteredData

      },
      updateData(){

        if(this.dialogRefund === true){
          this.editeditem.statusrefund = "refund"
          this.editeditem.refund = this.refundnominal
          this.editeditem.refundout = this.refundout
          this.editeditem.tglrefund = this.tglrefund
          this.editeditem.tglrefundout = this.tglrefundout
        }

        if(this.dialogAddon === true){
          this.editeditem.addon = this.addonnominal
          this.editeditem.tgladdon = this.tgladdon
        }


        this.dialogEdit = false
        this.dialogAddon = false
        this.dialogRefund = false
        let a = this

    axios.put(constant.BASE_URL + "ticket", a.editeditem).then(function (response) {

          console.log(response);

          Swal.fire({
            icon: 'success',
            title: 'berhasil',
            showConfirmButton: false,
            timer: 1500
          })
          
          a.getData()
          

        }).catch(function (error) {
     
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:  error
          })
          console.log(error);
        })
         

      },
      logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })
    }
    },
    created(){

    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")

    if(role == "admin"){
        this.isAdmin = true
        this.items = this.itemAdmin
      }

      if(role == "supervisor"){
        this.isSupervisor = true
        this.items = this.itemSpv
      }

    this.getData()

  }
}

</script>
<style>
.bodyhome{
  margin-top: -60px;
}



</style>