<template>
  <div class="bodyhome">
    
  <div  class="d-flex flex-row  mb-12">
    <v-card
    class=""
    min-height="760"
    width="256"
  >
    <v-navigation-drawer
      dark
      permanent
    >
      <v-list>

        <div class="pa-2">
          
          <v-btn v-on:click="menu" block>
            Menu
          </v-btn>
        </div>

        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          v-on:click="navigate(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title> 
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn @click="logout" block>
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
  </v-card>

  <v-dialog
        v-model="dialogTotalPiutang"
      max-width="700"
      >
      <v-card style="padding: 40px;">

        <v-row v-for="item in listTotalPiutang" :key="item.id">

             <p style="font-size: 16px;">{{ item.tgltransaksi }}</p>  
          
             <p style="font-size: 16px; margin-left: 20px;">{{ item.namapengguna }}</p> 
             
             <p style="font-size: 16px; margin-left: 20px;">{{ item.modatransportasi }}</p> 

             <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.totaltagihan) }}</p> 
        </v-row>


      </v-card>

  </v-dialog>

  
  <v-dialog
        v-model="dialogCitos"
      max-width="700"
      >
      <v-card style="padding: 40px;">

        <v-row v-for="item in listKomisiCitos" :key="item.id">

             <p style="font-size: 16px;">{{ item.tgltransaksi }}</p>  
          
             <p style="font-size: 16px; margin-left: 20px;">{{ item.namapengguna }}</p> 
             
             <p style="font-size: 16px; margin-left: 20px;">{{ item.modatransportasi }}</p> 

             <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.komisicitos) }}</p> 
        </v-row>


      </v-card>

  </v-dialog>

  <v-dialog
        v-model="dialogIuran"
      max-width="300"
      >
      <v-card style="padding: 40px;">

        <v-row v-for="item in listIuran" :key="item.id">

             <p style="font-size: 16px;">{{ item.bulan }}</p>  
          
             <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.iuran) }}</p> 
    
        </v-row>


      </v-card>

  </v-dialog>


  <v-dialog
        v-model="dialogRefund"
      max-width="700"
      >
      <v-card style="padding: 40px;">

        <v-row v-for="item in listRefund" :key="item.id">

             <p style="font-size: 16px;">{{ item.tgltransaksi }}</p>  
          
             <p style="font-size: 16px; margin-left: 20px;">{{ item.namapengguna }}</p> 
             
             <p style="font-size: 16px; margin-left: 20px;">{{ item.modatransportasi }}</p> 

             <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.refund) }}</p> 
        </v-row>


      </v-card>

  </v-dialog>

  <v-dialog
        v-model="dialogRefundOut"
      max-width="700"
      >
      <v-card style="padding: 40px;">

        <v-row v-for="item in listRefundOut" :key="item.id">

             <p style="font-size: 16px;">{{ item.tgltransaksi }}</p>  
          
             <p style="font-size: 16px; margin-left: 20px;">{{ item.namapengguna }}</p> 
             
             <p style="font-size: 16px; margin-left: 20px;">{{ item.modatransportasi }}</p> 

             <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.refundout) }}</p> 
        </v-row>


      </v-card>

  </v-dialog>

  <v-dialog
        v-model="dialogAddon"
        max-width="700"
      >
      <v-card style="padding: 40px;">

<v-row v-for="item in listAddon" :key="item.id">

     <p style="font-size: 16px;">{{ item.tgltransaksi }}</p>  
  
     <p style="font-size: 16px; margin-left: 20px;">{{ item.namapengguna }}</p> 
     
     <p style="font-size: 16px; margin-left: 20px;">{{ item.modatransportasi }}</p> 

     <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.addon) }}</p> 
</v-row>


</v-card>

  </v-dialog>

  <v-dialog
        v-model="dialogTicket"
        max-width="700"
      >
      <v-card style="padding: 40px;">

<v-row v-for="item in listTicket" :key="item.id">

     <p style="font-size: 16px;">{{ item.tgltransaksi }}</p>  
  
     <p style="font-size: 16px; margin-left: 20px;">{{ item.namapengguna }}</p> 
     
     <p style="font-size: 16px; margin-left: 20px;">{{ item.modatransportasi }}</p> 

     <p style="font-size: 16px; margin-left: 20px;">{{ formatRupiah(item.totaltagihan) }}</p> 
</v-row>


</v-card>

  </v-dialog>

  <horizontal-scroll>
    <div>

<v-row style="padding:40px;">
<v-col
  cols="3"
  sm="3"
  md="3"
>
  <v-menu
    ref="menu1"
    v-model="menu1"
    :close-on-content-click="false"
    :return-value.sync="startDate"
    transition="scale-transition"
    offset-y
    max-width="290"
  >
    <template v-slot:activator="{  on, attrs }">
      <v-text-field
        v-model="startDate"
        label="Start Date"
        prepend-icon="mdi-calendar"
        readonly
        v-on="on"
        v-bind="attrs"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="startDate"
      no-title
      scrollable
      @input="$refs.menu1.save(startDate)"
    >
      <!-- <v-spacer></v-spacer>
      <v-btn
        text
        color="primary"
        @click="menu1 = false"
      >
        Cancel
      </v-btn>
      <v-btn
      @click="$refs.menu1.save(startDate)"
        text
        color="primary"
      >
        OK
      </v-btn> -->
    </v-date-picker>
  </v-menu>
</v-col>

<v-col
  cols="3"
  sm="3"
  md="3"
>
  <v-menu
    ref="menu2"
    v-model="menu2"
    :close-on-content-click="false"
    :return-value.sync="endDate"
    transition="scale-transition"
    offset-y
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="endDate"
        label="End Date"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>
    <v-date-picker
      v-model="endDate"
      no-title
      scrollable
      @input="$refs.menu2.save(endDate)"
    >      
      <!-- <v-btn
        text
        color="primary"
        @click="menu2 = false"
      >
        Cancel
      </v-btn>
      <v-btn
        @click="$refs.menu2.save(endDate)"
        text
        color="primary"
   
      >
        OK
      </v-btn> -->
    </v-date-picker>
  </v-menu>
</v-col>
<v-col
  cols="2"
  sm="2"
  md="2"
>
<v-btn
@click="filterData"
color="primary"
>
Filter
</v-btn>
</v-col>

<v-col
  cols="2"
  sm="2"
  md="2"
>
<v-btn
@click="dialogEdit = true"
color="primary"
>
Ubah Data
</v-btn>

</v-col>


<v-dialog v-model="dialogEdit" max-width="500px">
    <v-card style="padding: 30px;">

        <v-text-field
         v-model="iuranbulanan"
         label="Iuran Bulanan"
         type="number"
        ></v-text-field>

        <!-- <v-text-field
         v-model="grossMargin"
         label="Gross Profit Margin"
         type="number"
        ></v-text-field> -->
        
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="dialogEdit = false" color="blue darken-1" text >Cancel</v-btn>
        <v-btn @click="perhitungan" color="blue darken-1" text >OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>


</v-row>

<div style="padding-left: 50px; text-align: center;">
  <h3 style="font-size: 40px; color: #2980b9;">{{ titlePage }}</h3>
</div>



<div class="d-flex " style="padding: 50px;">

<apexcharts  ref="realtimeChart"  width="600" height="500" type="line" :options="options" :series="series"></apexcharts >

<div style="margin-left: 60px; text-align: left;">


<h2 style="color: #0984e3;">Pendapatan</h2>

<h3 @click="popupTicketList" style="color: #0984e3; cursor: pointer;">Harga Tiket</h3>
<p @click="popupTicketList" style="color: #0984e3 !important;cursor: pointer;">{{ formatRupiah(hargatiket) }}</p>

<h3 @click="popupAddonList" style="color: #0984e3;cursor: pointer;">Add On</h3>
<p @click="popupAddonList" style="color: #0984e3 !important;cursor: pointer;">{{ formatRupiah(addon) }} </p>

<h3  @click="popupCitos" style="color: #0984e3; cursor: pointer;">Komisi Citos</h3>
<p  @click="popupCitos" style="color: #0984e3 !important; cursor: pointer;">{{ formatRupiah(komisicitos) }} </p>

<h3 @click="popupRefundList" style="color: #0984e3; cursor: pointer;">Refund In</h3>
<p @click="popupRefundList" style="color: #0984e3 !important; cursor: pointer;">{{ formatRupiah(totalrefund) }}</p>

<h3 @click="popupTicketList"  style="color: #0984e3; cursor: pointer;">Komisi Jamkrindo</h3>
<p @click="popupTicketList"  style="color: #0984e3 !important; cursor: pointer;">{{ formatRupiah(komisijamkrindo) }}</p>

<h3 style="color: #0984e3;">Total</h3>
<p style="color: #0984e3 !important;">{{ formatRupiah(totalpendapatan) }} </p>

</div>

<div style="margin-left: 60px; text-align: left;">

<h2 style="color: #e17055;">HPP</h2>

<h3 @click="popupTicketList" style="color: #e17055; cursor: pointer;">Harga Beli Tiket</h3>
 <p @click="popupTicketList" style="color: #e17055 !important;cursor: pointer;">{{  formatRupiah(hargatiket)}} </p>

 <h3 @click="popupAddonList" style="color: #e17055;cursor: pointer;">Add On</h3>
 <p @click="popupAddonList" style="color: #e17055 !important;cursor: pointer;">{{ formatRupiah(addon) }} </p>

 <h3 @click="popupIuran" style="color: #e17055;cursor: pointer;">Iuran Bulanan</h3>
 <p @click="popupIuran" style="color: #e17055 !important; cursor: pointer;">{{ formatRupiah(totaliuranbulanan) }}   </p>


 <h3 @click="popupRefundOut" style="color: #e17055;cursor: pointer;">Refund out</h3>
 <p @click="popupRefundOut" style="color: #e17055 !important;cursor: pointer;">{{ formatRupiah(totalrefundout) }}</p>

 <h3 style="color: #e17055;">Total</h3>
 <p style="color: #e17055 !important;">{{ formatRupiah(totalhpp) }} </p>

 </div>

 <div style="margin-left: 60px; text-align: left;">

  <h2 style="color: #00b894;">Laba</h2>

  <h3 style="color: #00b894;">Laba Kotor</h3>
  <p style="color: #00b894 !important;">{{ formatRupiah(labakotor) }}  </p>

  <h3 style="color: #00b894;">Gross Profit Margin</h3>
  <p style="color: #00b894 !important;">{{ grossMargin }} % </p>


  <h3 @click="popupPiutang"  style="color: #d63031; cursor: pointer;">Total Piutang</h3>
  <p @click="popupPiutang"  style="color: #d63031 !important; cursor: pointer;">{{ formatRupiah(totalpiutang) }}</p>

</div>

</div>

</div>

  </horizontal-scroll>

  </div>

    </div>
</template>

<script>
import VueApexCharts  from 'vue-apexcharts'
import Swal from 'sweetalert2'
import axios from 'axios';
import constant from '../util/constant';
import HorizontalScroll from 'vue-horizontal-scroll'



export default {
  name: 'detail-page',
  components: {
		apexcharts: VueApexCharts,
    HorizontalScroll
	},
  data () {
    return {
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu1: false,
      dialogEdit :false,
      dialogAddon:false,
      dialogRefund:false,
      dialogTicket:false,
      dialogRefundOut: false,
      dialogCitos:false,
      dialogIuran:false,
      dialogTotalPiutang:false,
      modal: false,
      menu2: false,
      hargatiket:0,
      desserts:[],
      komisicitos:0,
      addon:0,
      bep:0,
      komisijamkrindo:0,
      totalpendapatan:0,
      iuranbulanan:250000,
      totaliuranbulanan:0,
      totalhpp:0,
      labakotor:0,
      totalrefund:0,
      totalpiutang:0,
      startDate:"",
      endDate:"",
      totalrefundout:0,
      grossMargin:0,
      titlePage:"",
      cartPendapatan:[],
      cartHpp:[],
      cartLaba:[],
      cartPiutang:[],
      cartLunas:[],
      listRefund:[],
      listRefundOut:[],
      listAddon:[],
      listTicket:[],
      listKomisiCitos:[],
      listIuran:[],
      listTotalPiutang:[],
      listLunas:[],
      items: [
        { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
        { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      itemAdmin:[
      { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
        { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      itemSpv: [
        { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      starterdata:[0,0,0,0,0,0,0,0,0,0,0,0],
        options: {
        chart: {
          id: 'vuechart-example',
          animations: {
            speed: 200
          }
        },
        dataLabels: {
          enabled: false
        },
        plotOptions: {
          line: {
            distributed: true
          }
        },
        xaxis: {
          categories: ["jan","feb", "mar", "apr", "mei", "jun", "jul", "aug", "Sep", "oct", "nov", "dec"]
        }
      },
      series: [
        {
        name: 'pendapatan',
        data: []
      },{
        name: 'hpp',
        data: []
      }
      ,{
        name: 'laba',
        data: []
      },
      {
        name: 'tagihan',
        data: []
      },
      {
        name: 'lunas',
        data: []
      },

    ]
    }
    },
    methods:{
      popupRefundList(){
       this.dialogRefund = true
      },
      popupRefundOut(){
       this.dialogRefundOut = true
      },
      popupAddonList(){
        this.dialogAddon = true
      },
      popupCitos(){
        this.dialogCitos = true
      },
      popupPiutang(){
        this.dialogTotalPiutang = true
      },
      popupIuran(){
        this.dialogIuran = true
      },
      popupTicketList(){
        this.dialogTicket = true
      },
      formatRupiah(number) {
          const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
          });

          return formatter.format(number);
        },

      menu(){
        this.$router.push('/home');
      },
      navigate(link) {
      this.$router.push(link);
      },
      perhitungan(){
        this.dialogEdit = false

        this.komisijamkrindo =  (parseInt(this.hargatiket) +  parseInt(this.addon)) * 1/100
        this.totalpendapatan = parseInt(this.hargatiket) + parseInt(this.komisijamkrindo) + parseInt(this.addon) + parseInt(this.komisicitos)  
        this.totalhpp =  parseInt(this.hargatiket)  + parseInt(this.addon)  + parseInt(this.totaliuranbulanan)
        this.labakotor = parseInt(this.totalpendapatan) - parseInt(this.totalhpp)

        let komisipiutang = (parseInt(this.totalpiutang) + parseInt(this.addon)) * 1/100
        this.totalpiutang = parseInt(this.totalpiutang) + parseInt(komisipiutang)

        this.grossMargin = ((this.labakotor / parseInt(this.totalpendapatan)) * 100).toFixed(2) 
     
      },
      setPendapatan(item){
        let komisi = parseInt(item.totaltagihan) * 1 / 100
        let komisicitos = item.komisicitos
     

        if(komisicitos == ""){
          komisicitos = "0"
        }

        var harga = item.totaltagihan

        if(item.totaltagihan == ""){
          harga = "0"
        }


        let pendapatan = parseInt(harga) + parseInt(komisi) + parseInt(item.addon) + parseInt(komisicitos) 
           
        return pendapatan      
      },
      setHpp(item){
        var harga =item.totaltagihan
        if(item.totaltagihan == ""){
          harga = "0"
        }
        let hpp =  parseInt(harga)  + parseInt(item.addon) 

        return hpp 
      },
      setLaba(item){

        var harga = item.totaltagihan

        if(item.totaltagihan == ""){
          harga = "0"
        }
        let komisi = parseInt(harga ) * 1 / 100

        let komisicitos1 = item.komisicitos

        if(komisicitos1 == ""){
          komisicitos1 = "0"
        }

        
        let pendapatan = parseInt(harga) + parseInt(komisi) + parseInt(item.addon) + parseInt(komisicitos1) 
        let hpp =  parseInt(harga)  + parseInt(item.addon) 
        let laba = parseInt(pendapatan) - parseInt(hpp)

        return laba
      },
      setCartPendapatan(item){
        var date = new Date(item.tgltransaksi);
        var monthIndex = date.getMonth();
        let totalpendapatan = this.setPendapatan(item)
        //this.series[0].data[monthIndex]  += parseInt(totalpendapatan);
        this.cartPendapatan[monthIndex]  += parseInt(totalpendapatan);
      },

      setCartHpp(item){
        var date = new Date(item.tgltransaksi);
        var monthIndex = date.getMonth();
        let totalhpp = this.setHpp(item)
        // this.series[1].data[monthIndex] += parseInt(totalhpp);
         this.cartHpp[monthIndex] += parseInt(totalhpp);
      },

      setCartLaba(item){
        var date = new Date(item.tgltransaksi);
        var monthIndex = date.getMonth();
        let totalLaba = this.setLaba(item)
        // this.series[2].data[monthIndex] = parseInt(totalLaba);
         this.cartLaba[monthIndex] += parseInt(totalLaba);
      },

      setCartPiutang(item){
        var date = new Date(item.tgltransaksi);
        var monthIndex = date.getMonth();

        var harga = item.totaltagihan

        if(item.totaltagihan == ""){
          harga = "0"
        }
     
        this.cartPiutang[monthIndex] += parseInt(harga);
      },
      setCartLunas(item){
        var date = new Date(item.tgltransaksi);
        var monthIndex = date.getMonth();
        // this.series[3].data[monthIndex] += parseInt(item.totaltagihan);

        var harga = item.totaltagihan

        if(item.totaltagihan == ""){
          harga = "0"
        }
        
        this.cartLunas[monthIndex] += parseInt(harga);
      },

      setTotalIuranBulanan(monthlist){

        let listofmonth = monthlist.sort()
        var month = ""
        let a = this

        for (let i = 0 ; i < listofmonth.length; i ++){
         if(listofmonth[i] != month ){
              month = listofmonth[i]
              a.totaliuranbulanan += a.iuranbulanan
              a.listIuran.push({"bulan":month,  "iuran": a.iuranbulanan })
            }
        }
      },

      setCart(listItem){

        let a = this
        let newdata = listItem

        a.cartPendapatan = [0,0,0,0,0,0,0,0,0,0,0,0]
        a.cartHpp = [0,0,0,0,0,0,0,0,0,0,0,0]
        a.cartLaba = [0,0,0,0,0,0,0,0,0,0,0,0]
        a.cartPiutang = [0,0,0,0,0,0,0,0,0,0,0,0]
        a.cartLunas = [0,0,0,0,0,0,0,0,0,0,0,0]

        a.hargatiket = 0
        a.totalrefund = 0
        a.totalrefundout = 0
        a.komisicitos = 0
        a.addon = 0
        a.totaliuranbulanan = 0 
        a.totalpiutang = 0 

        a.listRefund = []
        a.listAddon = []
        a.listTicket = []
        a.listRefundOut = []
        a.listKomisiCitos = []
        a.listIuran = []
        a.listTotalPiutang = []

      
       var monthlist = []
        

        for (let i = 0; i < newdata.length; i++){

        if(a.isCurrentYear(newdata[i].tgltransaksi)){

            var harga = newdata[i].totaltagihan

            if(newdata[i].totaltagihan == ""){
              harga = "0"
            }

            a.hargatiket += parseInt(harga)    
            a.totalrefund +=  parseInt(newdata[i].refund)   
            a.totalrefundout += parseInt(newdata[i].refundout)
            a.addon += parseInt(newdata[i].addon)


            a.listTicket.push(newdata[i])

            if(newdata[i].refundout > 0){
            a.listRefundOut.push(newdata[i])
            }

            if(newdata[i].refund > 0){
            a.listRefund.push(newdata[i])
            }

            if(newdata[i].addon > 0){
            a.listAddon.push(newdata[i])
            }
        
            let komisicitos1 = newdata[i].komisicitos

            if(komisicitos1 === ""){
              komisicitos1 = "0"
            }

            if(komisicitos1 > 0){
            a.listKomisiCitos.push(newdata[i])
            }
            monthlist.push(a.convertToMonth(newdata[i].tgltransaksi))

            
            a.komisicitos += parseInt(komisicitos1)
            a.setCartPendapatan(newdata[i])
            a.setCartHpp(newdata[i])
            a.setCartLaba(newdata[i])
            if(newdata[i].status != "lunas"){
              a.setCartPiutang(newdata[i])

              var hargapiutang = newdata[i].totaltagihan

                if(newdata[i].totaltagihan == ""){
                  hargapiutang = "0"
                }
              
              a.totalpiutang += parseInt(hargapiutang)  
              a.listTotalPiutang.push(newdata[i])
            }

            if(newdata[i].status == "lunas"){
              a.setCartLunas(newdata[i])
            }

          }
        }

        a.setIuranBulanan()

        a.setTotalIuranBulanan(monthlist)

        a.updateSeriesLine();
       
        a.perhitungan()

      },

      setIuranBulanan(){
        for(let j = 0; j < this.cartHpp.length; j++){
          this.cartHpp[j] =  parseInt(this.cartHpp[j]) + parseInt(this.iuranbulanan);
          this.cartLaba[j] -= parseInt(this.iuranbulanan);
        }
      },

      

      filterData(){
        const filteredData = this.desserts.filter(item => {
          const itemDate = new Date(item.tgltransaksi);
          return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
        });

        this.titlePage = "Kinerja LOB Ticket Periode " + this.startDate + " - " + this.endDate

        this.setCart(filteredData)

      },

      convertToMonth(dateString) {
          const date = new Date(dateString);
          const month = date.toLocaleString('default', { month: 'short' });
          return month;
      },
      setTitle(){
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear(); 
        this.titlePage = "Kinerja LOB Ticket Periode " + currentYear
        
      },

      isCurrentYear(dateString) {
          const currentDate = new Date(); // Get the current date
          const currentYear = currentDate.getFullYear(); // Get the current year
          
          const providedDate = new Date(dateString); // Convert the provided dateString to a Date object
          const providedYear = providedDate.getFullYear(); // Get the year from the provided date
          
          return currentYear === providedYear;
        },
   

      updateSeriesLine() {

         this.series[0].data = this.cartPendapatan
         this.series[1].data = this.cartHpp
         this.series[2].data = this.cartLaba
         this.series[3].data = this.cartPiutang
         this.series[4].data = this.cartLunas

         
          this.$refs.realtimeChart.updateSeries([
            {
            data: this.series[0].data,
          },
          {
            data: this.series[1].data,
          },
          {
            data: this.series[2].data,
          },
          {
            data: this.series[3].data,
          },
          {
            data: this.series[4].data,
          }
        ], false, true);
        },

      getData(){

      let a = this  

      axios.get(constant.BASE_URL + "ticket")
      .then(function (response) {

       let newdata = response.data.data;
       console.log(newdata)
       a.desserts = newdata
       a.setCart(newdata)
      
      }).catch(function (error) {     
        console.log(error);
      })

      },

      sortDates(dateArray) {
        const sortedArray = dateArray.sort((a, b) => {
          return new Date(a) - new Date(b);
        });

        return sortedArray;
      },

      logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })

    }
    },

    created(){

    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")

  if(role == "admin"){
    this.isAdmin = true
    this.items = this.itemAdmin
  }

  if(role == "supervisor"){
    this.isSpv = true
    this.items = this.itemSpv
  }

   
  },
  mounted(){
    this.getData()
    this.setTitle()
  }

}

</script>
<style>

.bodyhome{
  margin-top: -60px;
}

h2{
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
}

p{
  font-style: normal;
  text-decoration: none !important;
  color: grey !important;
}

li {
  display: inline;
  padding: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #34758E;

}


</style>