
// const BASE_URL = "http://localhost:8080/";
const BASE_URL = "https://bjs-ticketing.herokuapp.com/";


export default {
    BASE_URL : BASE_URL
}


