<template>
<div class="bodyhome fill-height">

    <v-container class="d-flex justify-start my-auto">

<v-row>

  <div class="ms-auto">
    <v-btn
      @click="logout"
      style="margin-top: 50px; font-size: 20px;padding:16px;"
      depressed
      color="primary"
    >
      Logout
    </v-btn>
  </div>

</v-row>

</v-container>

<v-container
class="d-flex justify-center mb-6"
flat
tile
>

<v-row class="mt-15 mx-auto justify-center mb-6">

<v-card

@click="document"
class="my-12 card"
max-width="374"
>

<v-img
height="250"
max-width="250"
src="../assets/transaction.png"
></v-img>

<v-card-title class="d-flex justify-center mt-10"><p>Transactions</p></v-card-title>

</v-card>

<v-card

@click="piutang"
class="my-12 card"
max-width="374"
>

<v-img
height="250"
max-width="250"
src="../assets/piutang.png"
></v-img>

<v-card-title class="d-flex justify-center mt-10"><p>Penagihan</p></v-card-title>

</v-card>


<v-card

@click="detail"
class="my-12 card"
max-width="374"
>

<v-img
height="250"
max-width="250"
src="../assets/detail.png"
></v-img>

<v-card-title class="d-flex justify-center mt-10"><p>Detail</p></v-card-title>

</v-card>



</v-row>
</v-container>



</div>
</template>
<script>
import Swal from 'sweetalert2'

export default {
    name: 'home-view-page',
    data () {
      return {
      isSupervisor:false,
      isAdmin:false,
    }},
    methods: {
    document() {
      this.$router.push('/listtransaction');
    },
    piutang() {
      this.$router.push('/listpiutang');
    },
    detail() {
      this.$router.push('/detail');
    },
    logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })

    }

  },
  created(){
    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }
  }

}


</script>

<style>
.bodyhome {
  background-image: url(../assets/background.png);
    background-repeat: repeat-y ;
    background-size:  cover;
   margin-top: -60px;
  
  
}

.swalTitle{
 
}

.card {
  margin: 20px;
  padding: 15px;
  /* background: linear-gradient(180deg, rgba(86, 207, 255, 0.7) 0%, rgba(35, 48, 46, 0) 100%); */
  background: rgba(86, 207, 255, 0.7);
  border-radius: 15px;
}

p{

  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  text-decoration-line: underline;
  color: #262fa1;
}

h1{
 
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  color: #34758E;
}




</style>