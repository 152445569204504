<template>
<div class="bodyhome">

<div  class="d-flex flex-row  mb-12">
  <v-card
  class=""
  min-height="760"
  width="256"
>
  <v-navigation-drawer
    dark
    permanent
  >
    <v-list>

      <div class="pa-2">
        <v-btn v-on:click="menu" block>
          Menu
        </v-btn>
      </div>

      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        v-on:click="navigate(item.route)"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>
        <v-list-item-content >
              <v-list-item-title>{{ item.title }}</v-list-item-title> 
          </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="pa-2">
        <v-btn @click="logout" block>
          Logout
        </v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</v-card>

<horizontal-scroll>

  <div class="mx-auto" style="padding: 50px; text-align: center;">

    <v-row>
    <v-col
      cols="3"
      sm="3"
      md="3"
    >
      <v-menu
        ref="menu1"
        v-model="menu1"
        :close-on-content-click="false"
        :return-value.sync="startDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="startDate"
            label="Start Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          no-title
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            @click="menu1 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu1.save(startDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
 
    <v-col
      cols="3"
      sm="3"
      md="3"
    >
      <v-menu
        ref="menu2"
        v-model="menu2"
        :close-on-content-click="false"
        :return-value.sync="endDate"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="endDate"
            label="End Date"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="endDate"
          no-title
          scrollable
        >
          
          <v-btn
            text
            color="primary"
            @click="menu2 = false"
          >
            Cancel
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$refs.menu2.save(endDate)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
    <v-col
      cols="2"
      sm="2"
      md="2"
    >
   <v-btn
   @click="filterData"
   color="primary"
   >
    Filter
   </v-btn>
  </v-col>

  <v-col
      cols="3"
      sm="3"
      md="3"
    >
    <v-text-field
        class="rounded ms-auto"
        append-icon="mdi-magnify"
        v-model="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
   
  </v-col>


  </v-row>

<v-data-table
  v-model="selected"
  item-key="id"
  show-select
  :search="search"
  :headers="headers"
  :items="desserts"
  class="elevation-1 "
>
  <template v-slot:top>
    <v-toolbar
      flat
    >
      <v-toolbar-title>List Tagihan</v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        max-width="500px"
      >
    ]
        <v-card>
       

          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
  
                    label="Dessert name"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                
                    label="Calories"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
             
                    label="Fat (g)"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
         
                    label="Carbs (g)"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
             
                    label="Protein (g)"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
             
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
             
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog  max-width="500px">
        <v-card>
          <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text>Cancel</v-btn>
            <v-btn color="blue darken-1" text >OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
  </template>
  <template v-slot:[`item.actions`]="{ item }">
    <v-icon
      small
      class="mr-2"
      @click="editItem(item)"
    >
      mdi-pencil
    </v-icon>
    <!-- <v-icon
      small
      @click="deleteItem(item)"
    >
      mdi-delete
    </v-icon> -->
  </template>

  <template v-slot:[`item.totaltagihan`]="{ item }">

<p style="font-size: 13px;">{{ formatRupiah(item.totaltagihan) }}</p>

</template>
  <template v-slot:no-data>
    <p>No Data</p>
  </template>
</v-data-table>


<div class="d-flex">

  <download-excel :data="selected">
  <v-btn @click="penagihan"  style="margin-top: 40px;" color="primary">
    Ajukan Penagihan
  </v-btn>
</download-excel>

<v-btn @click="pelunasan"  style="margin-top: 40px; margin-left: 40px;" color="primary">
    Pelunasan
  </v-btn>


</div>



</div>

</horizontal-scroll>


</div>

</div>
</template>

<script>
 import HorizontalScroll from 'vue-horizontal-scroll'
 import 'vue-horizontal-scroll/dist/vue-horizontal-scroll.css'
 import Swal from 'sweetalert2'
 import axios from 'axios';
 import constant from '../util/constant';
//  import JsonCSV from 'vue-json-csv'
// import VueJsonToCsv from 'vue-json-to-csv'
// import JsonExcel from "vue-json-excel";


export default {
  name: 'piutang-page',

  components: {
        HorizontalScroll
    },

  data () {
      return {
      date: "",
      menu1: false,
      modal: false,
      menu2: false,
      search:"",
      startDate: "",
      endDate:"",
      isSupervisor:false,
      isAdmin:false,
      items: [
        { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
          { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
          { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
          { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
          { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
        ],
        itemAdmin:[
      { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
        { title: 'Input', icon: 'mdi-arrow-up-bold-box-outline', route: '/input' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      itemSpv: [
      { title: 'Transaction', icon: 'mdi-view-dashboard', route: '/listtransaction' },
        { title: 'Tagihan', icon: 'mdi-dialpad', route: '/listpiutang' },
        { title: 'Detail', icon: 'mdi-domain', route: '/detail' },
        { title: 'Profile', icon: 'mdi-account-box', route: '/profile' }
      ],
      dialog: false,
      selected: [],
      downloadlist:[],
      dialogDelete: false,
      headers: [
        {
          text: 'Tgl Transaksi',
          align: 'start',
          sortable: false,
          value: 'tgltransaksi',
        },
        { text: 'Nama pemesan', value: 'namapemesan' },
        { text: 'No Hp', value: 'nohp' },
        { text: 'Nama Pengguna', value: 'namapengguna' },
        { text: 'No Ktp', value: 'noktp' },
        { text: 'Katergori', value: 'kategori' },
        { text: 'Jenis TIket (Maskapai)', value: 'maskapai' },
        { text: 'Tanggal Keberangkatan', value: 'tglberangkat' },
        { text: 'Keberangkatan', value: 'tempatberangkat' },
        { text: 'Tujuan', value: 'tujuan' },
        { text: 'Kode Booking', value: 'kodebooking' },
        { text: 'Harga Tiket', value: 'totaltagihan' },
        { text: 'Status', value: 'status' },
      ],
      desserts: [],
      savedlist:[],
      editedIndex: -1,
      }
    },
    
    computed: {

    },

    methods: {
      menu(){
        this.$router.push('/home');
      },
 
      navigate(link) {
        this.$router.push(link);
        },
        penagihan(){

          for(let i = 0; i < this.selected.length; i++){
              var editeditem = this.selected[i]
              editeditem.status = "tertagih"
             this.updateData(editeditem)
          }
          this.getData()

        },
        pelunasan(){

            for(let i = 0; i < this.selected.length; i++){
                var editeditem = this.selected[i]
                if(editeditem.status === "tertagih"){
                editeditem.status = "lunas"
              this.updateData(editeditem)
                }
            }
            this.getData()

        },  
        updateData(item){
              
          for(let i = 0; i < this.savedlist.length; i++){
            if(item.id === this.savedlist[i].id){
              this.savedlist[i].status = item.status
              axios.put(constant.BASE_URL + "ticket", this.savedlist[i]).then(function (response) {
                console.log(response);
              }).catch(function (error) {
                console.log(error);
              })
            }

          }

           
              
            },

     getData(){
        let a = this

        a.desserts = []
        a.savedlist = []

          axios.get(constant.BASE_URL + "ticket")
          .then(function (response) {

          let newdata = response.data.data;

        
          for(let i =0; i < newdata.length; i ++){
            if(newdata[i].status != "lunas" ){
            
              var newitem = newdata[i]
              a.savedlist.push(newitem) 
              a.desserts.push({  
                "id": newitem.id, 
                "tgltransaksi": newitem.tgltransaksi,   
                "namapemesan":  newitem.namapemesan,  
                "nohp": newitem.nohp,    
                "namapengguna": newitem.namapengguna,
                "noktp":  newitem.noktp,
                "kategori":newitem.kategori,
                "modatransportasi":  newitem.modatransportasi,
                "tglberangkat":  newitem.tglberangkat,
                "maskapai": newitem.maskapai,
                "tempatberangkat":  newitem.tempatberangkat,
                "tujuan":  newitem.tujuan,
                "kodebooking":  newitem.kodebooking,
                "totaltagihan": newitem.totaltagihan,
                "status": newitem.status
              })
            }
          }

          console.log(newdata)

      
          }).catch(function (error) {     
          console.log(error);
          })

      },
      formatRupiah(number) {
          const formatter = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR'
          });

          return formatter.format(number);
        },
      filterData(){
        const filteredData = this.desserts.filter(item => {
          const itemDate = new Date(item.tgltransaksi);
          return itemDate >= new Date(this.startDate) && itemDate <= new Date(this.endDate);
        });

        this.desserts = filteredData

      },


      logout(){
      Swal.fire({
          title: "<h3 style='font-family:cambria;'>Lanjutkan Logout</h3>",
          showCancelButton: true,
          icon:'success',
          confirmButtonColor: '#3085d6',
          confirmButtonText: "<h3 style='font-family:cambria;'>Logout</h3>",
          cancelButtonText: "<h3 style='font-family:cambria;'>Cancel</h3>"

        }).then((result) => {
          if (result.isConfirmed) {
            localStorage.clear()
              this.$router.push("/");
          }
        })

    }
    },
    watch:{
      
    },
    created(){

    var userid = localStorage.getItem("userid")
    if (userid == null) {
      this.$router.push("/");
    }

    var role = localStorage.getItem("userRole")

  if(role == "admin"){
    this.isAdmin = true
    this.items = this.itemAdmin
  }

  if(role == "supervisor"){
    this.$router.push("/");
  }

    this.getData()

  }


}

</script>
<style>
.bodyhome{
  margin-top: -60px;
}



</style>